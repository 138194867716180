@import "~@luciad/ria-sample-common/template/css/sample-common-style.css";
@import "~@luciad/ria-sample-common/slider/SliderComponent.css";
@import "./src/ruler3d/Ruler3DPanel.css";

.active {
  background-color: var(--primary);
}

.btn.panelButton.active:focus {
  background-color: var(--primary) !important;
}

th, td {
  padding-left: 4px;
  padding-right: 4px;
  color: #d8dad3;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

.luciad .lcdBalloon .lcdFrame {
  background-color: #bc4f3c;
  margin-bottom: 20px;
}

.luciad .lcdBalloon .lcdContent {
  width: 300px;
}

.poiLabel {
  color: ghostwhite;
  text-shadow: 2px 2px 5px #1d2d3f;
}

label {
  padding: 0 5px 0 26px;
}

.quality-slider .slider-value {
  width: 70px;
}

#height-slider input[type="range"] {
  width: 260px;
}

#intensity-colormap-slider, #height-colormap-slider {
  display: none;
}

.railwayIntensity #intensity-colormap-slider, .railwayHeight #height-colormap-slider {
  display: inline-block;
}

.controlGroup {
  max-height: calc(100% - 122px);
}

.panelButton.emphasize {
  border-bottom: 3px solid whitesmoke;
}

.panelButton.noEmphasize {
  margin-bottom: 3px;
}

.slider.effectSlider label {
  text-transform: none;
  padding: 0 0 0 0;
  max-width: 65px;
  width: 65px;
  font-weight: normal;
}

.slider.effectSlider .slider-value-right {
  padding: 0 0 0 0;
  display: inline-block;
  max-width: 20px;
}

.slider.effectSlider .slider-control {
  margin-right: 3px;
  margin-left: 3px;
  max-width: 145px;
  width: 145px;
}

.form-inline {
  margin: 0;
  padding: 0;
  width: 100%;
  height: fit-content;
  left: 0;
  bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

#messageoverlay {
  z-index: 50;
  background-color: black;
  opacity: 0;
  visibility: hidden;
}

.chooseArea #messageoverlay {
  opacity: 0.75;
  visibility: visible;
}

#after {
  background-color: var(--input-bg);
}

#btnContainer {
  width: 100%;
  min-width: fit-content;
  height: 90px;
  padding: 10px 0 3px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--input-bg);
}

#btnContainer .container {
  width: 92px;
  margin: 0 5px 0 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--primary);
}

#btnContainer .container:hover {
  background-color: var(--primary);
  color: var(--btn-primary-color);
}

#btnContainer .container.active {
  background-color: var(--primary);
  border-color: var(--secondary);
  color: var(--btn-primary-color);
  box-shadow: 0 0 0 0.2rem rgb(193 217 42 / 25%);
}

.container img {
  display: block;
  height: 54px;
  min-width: 100%;
}

.container p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  padding: 3px;
  margin: 0;
  font-size: 10px;
  white-space: nowrap;
}

/* Add layer component styles */
.no-items-panel {
  text-align: center;
  position: relative;
  top: 50%;
  margin-top: -10px;
  line-height: 20px;
  font-style: italic;
}

#wmslayerspanel {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.addui h3, .shareui h3 {
  text-align: left;
  margin: -30px 0 20px 5px;
  padding: 10px 0;
  font-size: 20px;
  border: 1px solid var(--body-color);
  border-width: 0 0 1px 0;
}

.addui .form-inline label, .shareui .form-inline label {
  width: 20%;
}

.addui .form-inline .form-control, .shareui .form-inline .form-control {
  width: 75%;
}

#overlay, #sp-overlay {
  position: absolute;
  z-index: 50000;
  top: 0;
  left: 0;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  display: table;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

#overlay.visible, #sp-overlay.visible {
  opacity: 1;
  visibility: visible;
}

#loadingContainer, #sp-loading-container {
  display: table-cell;
  vertical-align: middle;
}

#loading, #sp-loading {
  width: 600px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
  border: 3px solid var(--primary);
}

#loadingMessage {
  font-size: 2em;
}

.panel-row .style {
  float: right;
  width: 66%;
}

.panel-row .label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none;
  margin: -3px 0 0 0;
}

.panel-row .checkbox {
  float: left;
  width: 34%;
  text-align: left;
}

.panel-row:after {
  clear: both;
}

.panel-row:before, .panel-row:after {
  display: table;
  content: " ";
}

.panel-row.layer-row {
  padding-left: 12px;
  float: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-row.layer-row .checkbox {
  float: none;
  width: calc(34% + 12px);
  display: flex;
  align-items: center;
}

.panel-row.layer-row .style {
  width: calc(66% - 12px);
}

.panel-row.layer-row .checkbox .label {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  white-space: normal;
}

.panel-row.layer-row .checkbox .label input {
  padding: 0;
  margin: 0 3px 0 0;
}

.panel-row .lcdIcon {
  float: right;
  margin: 3px 0 5px 5px;
}

.panel-row .lcdIcon:hover {
  color: var(--primary);
}

.firststep .nextstep {
  width: 50%;
}

.panel-row-buttons {
  margin: 20px 0 0 0;
}

.panel-row-buttons .previousstep {
  width: 30%;
  float: left;
}

.panel-row-buttons .nextstep {
  width: 30%;
  float: right;
}

@media (max-width: 700px) {
  #toasts {
    top: 33%;
  }
  #overlay, #share-panel-overlay {
    position: fixed;
  }
  #loadingContainer, #sp-loading-container {
    display: block;
    height: 100%;
  }
  #loading, #sp-loading {
    width: 100%;
    padding: 10px;
    height: 100%;
    background: #000000;
  }
  .firststep .nextstep {
    width: 100%;
  }
  .panel-row-buttons {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .panel-row-buttons .form-control {
    width: 100%;
    margin: 15px 0 0 0;
  }
}
